<template>
  <div class="about h-100 d-flex flex-column" id="main">
    <header class="flex-shrink-1">
      <NavComponent></NavComponent>
    </header>

    <div id="logoImgs" class="d-none d-lg-flex justify-content-center">
      <router-link to="/">
        <LogoImage
          pWidthLogo="10vw"
          pMaxWidthLogo="500"
          pWidthSubt="16vw"
          pMaxWidthSubt="400"
        />
      </router-link>
    </div>
      
    <v-container class="container-sobre" style="display: flex; flex-direction: column; flex: auto;">
        <nav id="about-nav" class="w-100">
          <ul>
            <li :class="$route.path === item.path ? 'selected' : ''" v-for="item in bar" :key="item.name" @click="$router.push(`${item.path}`); page = item.value">
              <span v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)" v-text="item.name"></span>
              <v-icon left class="mx-2" v-text="item.icon"></v-icon>
            </li>
          </ul>
        </nav>
        
        <router-view></router-view>

      <div style="flex-grow: 1;" />
      <div v-if="['xs', 'sm'].includes(size)">
        <footer class="d-flex flex-column justify-content-center">
            <div class="content-footer-center">
                Desenvolvido pela CODIN da Procuradoria-Geral do Estado de Sergipe em conjunto com a Secretaria de Estado Geral de Governo
            </div>
            <div class="content-footer-center mt-4">
                <strong>LegisOn {{version}}</strong> <br />
                2022
            </div>
        </footer>
      </div>
      <div v-else class="d-flex flex-column">
        <footer class="d-flex justify-content-between">
            <div class="content-footer-left">
                <strong>LegisOn {{version}}</strong> <br />
                2022
            </div>
            <div class="content-footer-right">
                Desenvolvido pela CODIN da Procuradoria-Geral do Estado de Sergipe em conjunto com a Secretaria de Estado Geral de Governo
            </div>
        </footer>
      </div>
    </v-container>
  </div>
</template>

<script>
import LogoImage from "@/shared/components/LogoImage.vue";
import NavComponent from "@/shared/components/NavComponent.vue";
import { mdiInformationOutline } from '@mdi/js';
import { mdiNewspaperVariantOutline } from '@mdi/js';
// import { mdiCastEducation } from '@mdi/js';
import { mdiAccountGroupOutline } from '@mdi/js';
import { mdiChartTimelineVariant } from '@mdi/js';
import { mdiFileDocumentOutline } from '@mdi/js';
import { mdiUpdate } from '@mdi/js';

export default {
  name: "About",
  data: () => {
    return ({
      version: "1.0.0",
      page: "sobre",
      bar: [
        {name: "Sobre", value: "sobre", path: "/sobre", icon: mdiInformationOutline},
        {name: "Novidades", value: "news", path: "/sobre/novidades", icon: mdiNewspaperVariantOutline},
        /*{name: "Tutoriais", value: "tutorial", path: "/sobre/tutoriais", icon: mdiCastEducation},*/
        {name: "Equipe", value: "team", path: "/sobre/equipe", icon: mdiAccountGroupOutline},
        {name: "Linha do Tempo", value: "timeline", path: "/sobre/timeline", icon: mdiChartTimelineVariant},
        {name: "Documentos Oficiais", value: "documents", path: "/sobre/documentos", icon: mdiFileDocumentOutline},
        {name: "Notas de Atualização", vaçie: "atualizacoes", path: "/sobre/atualizacoes", icon: mdiUpdate}
      ]
    })
  },
  beforeRouteLeave(to, from, next) {
      localStorage.setItem('prevPage', from.path);
      next();
    },
  components: {
    NavComponent,
    LogoImage,
  },
  mounted() {
    this.$http.get('/Public/Version').then(res => {
      this.version = res.data;
    }).catch(err => {
      console.log(err);
    });
  },
  computed: {
      size: function () {
          return this.$vuetify.breakpoint.name;
      }
  }
};
</script>

<style>
@import "./About.css";
</style>
